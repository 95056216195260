import { IconSuccess } from '@/components/ui/icons/IconSuccess';
import { IconError } from '@/components/ui/icons/IconError';
import { IconInfo } from '@/components/ui/icons/IconInfo';
import { AlertMode } from '@/components/ui/Alert';

export const getAlertIcon = (mode: AlertMode) => {
  switch (mode) {
    case AlertMode.Success:
      return IconSuccess;
    case AlertMode.Error:
      return IconError;
    default:
      return IconInfo;
  }
};
