import { useMemo } from 'react';
import i18Next from '@/middleware/i18n';
import { idX } from '@/lib/helpers/idx';
import { AnyObject } from '@/typedefs/common';

export const useRouterQuery = <T extends AnyObject>(
  mapper: ((query: AnyObject) => T) = idX,
): T => {
  const router = i18Next.useRouter();

  return useMemo(() => mapper(router.query), [router.query, mapper]);
};
