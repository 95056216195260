import React, {
  DetailedHTMLProps, FC, HTMLAttributes, useState,
} from 'react';
import { cn } from '@/lib/classNames';
import { IconClose } from '@/components/ui/icons/IconClose';
import { getAlertIcon } from '@/components/ui/Alert/helpers/getAlertIcon';
import { Button } from '@/components/ui/Button';
import styles from './Alert.module.scss';

export enum AlertMode {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  System = 'system',
}

type AlertElementProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface CustomProps {
  /** Alert mode. Represents the visual style of the alert. */
  mode: AlertMode;
  /** If true, the close button will be rendered. */
  renderCloseButton?: boolean;
  /** Callback function that is called when the close button is clicked. */
  onClose?: (shouldHide: boolean) => void;
  /** If true, the alert icon will be rendered. */
  shouldShowIcon?: boolean;
  /** Data attribute for qa automation */
  dataQa?: string;
}

type AlertProps = AlertElementProps & CustomProps;

type AlertType = FC<AlertProps> & {
  mode: typeof AlertMode;
};

/**
 * Alert component. Used to display messages to the user.
 * */
export const Alert: AlertType = (props) => {
  const {
    mode,
    renderCloseButton,
    onClose,
    children,
    shouldShowIcon = true,
    className,
    dataQa = 'alert',
  } = props;

  const [hideAlert, setHideAlert] = useState(false);
  const AlertIcon = getAlertIcon(mode);

  if (hideAlert) {
    return null;
  }

  const closeAlert = () => {
    setHideAlert(true);
    if (onClose) {
      onClose(true);
    }
  };

  return (
    <div className={cn(styles.alert, mode, className)} data-qa={dataQa}>
      {shouldShowIcon && (
        <div className={styles.iconWrapper}>
          <AlertIcon />
        </div>
      )}

      <div data-qa='common-site-alert' className={styles.contentWrapper}>
        {children}
      </div>

      {renderCloseButton && (
        <Button
          type="button"
          className={styles.closeButton}
          onClick={closeAlert}
          LeftIcon={IconClose}
          mode={Button.mode.TransparentLight}
          size={Button.size.Small}
        />
      )}
    </div>
  );
};

Alert.mode = AlertMode;
